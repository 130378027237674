/*******************************
         Site Overrides
*******************************/

/* WhatsApp styles */
.ui.inverted.menu .whatsapp.active.item,
.ui.inverted.menu .whatsapp.active.item:hover,
.ui.inverted.whatsapp.menu {
  background-color: @whatsappSecondary;
}
.ui.inverted.whatsapp.menu .item:before {
  background-color: @whatsappSecondary;
}
.ui.inverted.whatsapp.menu .active.item {
  background-color: @whatsappSecondary !important;
}

.ui.whatsapp.button, .ui.whatsapp.button:active {
  background-color: @whatsapp;
  color: @white;
}

.ui.whatsapp.button:hover {
  background-color: @whatsappMid;
  color: @white;
}

/* iPECS chat styles */
.ui.inverted.menu .chat.active.item,
.ui.inverted.menu .chat.active.item:hover,
.ui.inverted.chat.menu {
  background-color: @chatSecondary;
}
.ui.inverted.chat.menu .item:before {
  background-color: @chatSecondary;
}
.ui.inverted.chat.menu .active.item {
  background-color: @chatSecondary !important;
}

.ui.chat.button, .ui.chat.button:active {
  background-color: @chat;
  color: @white;
}

.ui.chat.button:hover {
  background-color: @chatMid;
  color: @white;
}

/* Engage styles */
.ui.inverted.menu .engage.active.item,
.ui.inverted.menu .engage.active.item:hover,
.ui.inverted.engage.menu {
  background-color: @engageSecondary;
}
.ui.inverted.engage.menu .item:before {
  background-color: @engageSecondary;
}
.ui.inverted.engage.menu .active.item {
  background-color: @engageSecondary !important;
}

.ui.engage.button, .ui.engage.button:active {
  background-color: @engage;
  color: @white;
}

.ui.engage.button:hover {
  background-color: @engageMid;
  color: @white;
}
